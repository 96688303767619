import React from "react";
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {Title, Button, Section, Box, Text, Input} from "../components/Core";
import MapGoogle from "../components/MapGoogle";

import PageWrapper from "../components/PageWrapper";

const FormStyled = styled.form``;

const Contact2 = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container className="position-relative">
            <Row>
              <Col lg={6} className="mb-5 mb-lg-0">
                <div className="banner-content mb-5">
                  <Title variant="hero">Contact us</Title>
                  <Text>
                    Create custom landing pages with Omega that converts <br className="d-none d-md-block" /> more visitors than any website.{" "}
                  </Text>
                </div>
                <div>
                  <form name="contact form" method="POST" data-netlify="true" action="/thank-you">
                    <input type="hidden" name="form-name" value="contact form" />
                    <Box mb={4}>
                      <Title variant="card" fontSize="18px" as="label" htmlFor="nameput">
                        Your name
                      </Title>
                      <Input type="text" placeholder="i.e. James Cameron" id="nameput" name="Name" />
                    </Box>
                    <Box mb={4}>
                      <Title variant="card" fontSize="18px" as="label" htmlFor="emailput">
                        Email address
                      </Title>
                      <Input type="email" placeholder="i.e. james@email.com" id="emailput" name="Email" />
                    </Box>

                    <Box mb={4}>
                      <Title variant="card" fontSize="18px" as="label" htmlFor="phone">
                        Phone Number
                      </Title>
                      <Input type="text" placeholder="Best contact number" id="phone" name="Phone" />
                    </Box>

                    <Box mb={4}>
                      <Title variant="card" fontSize="18px" as="label" htmlFor="messageput" name="Message">
                        Message
                      </Title>
                      <Input type="text" as="textarea" placeholder="Write your message here" rows={4} id="messageput" />
                    </Box>

                    <Button width="100%" type="submit" borderRadius={10}>
                      Send Message
                    </Button>
                  </form>
                </div>
              </Col>
              <Col lg={8} className="position-static">
                <MapGoogle />
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default Contact2;
